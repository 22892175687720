import React from 'react';
import MainLayout from '../../components/MainLayout';
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

function OrdersIndex() {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Orders</title>
    </Helmet>      
    <MainLayout 
      breadcrumbsContent = {
        <BreadcrumbGroup
          items={
            [
              { text: "Orders", href: "/orders/" },
            ]
          }
          ariaLabel="Breadcrumbs"
      />
      }
      activeHref={"/orders"}
    >
          <Container
            header={
              <Header
                variant="h2"
                // description="Container description"
              >
                Welcome
              </Header>
            }
          >
              <p>Welcome to Getz Orders Management</p>
              <p>Start monitoring orders in <Link to="/order/list" >here</Link></p>
          </Container>          
    </MainLayout>
    </>
  );
};

export default OrdersIndex;

