import React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";

const DetailCellRenderer = (params) => {
    var orderType = "NA"
    switch (params.data.order_type) {
        case 0:
            orderType = "Delivery"
            break;
        case 1:
            orderType = "Pick Up"
            break;
        case 2:
            orderType = "Dine In"
            break;
        default:
            orderType = "NA"
            break;
        }    
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container padding={2} spacing={2}>
          <Grid xs={4}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Customer Info
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Name: {params.data.customer_name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Contact: {params.data.customer_contact}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Email: {params.data.customer_email}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Future Feature 1</Button>
                <Button size="small">Future Feature 2</Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid xs={4}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Delivery Info
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Order Id: {params.data.id}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Delivery Operator Name: {params.data.delivery_operator_name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Delivery Operator Id: {params.data.delivery_operator_id}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Delivery Id: {params.data.delivery_id}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Delivery Status Code: {params.data.delivery_status_code}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Delivery Status: {params.data.delivery_status}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Delivery Vehicle: {params.data.delivery_vehicle}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Delivery Driver Name: {params.data.delivery_driver_name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Delivery Driver Contact:{" "}
                  {params.data.delivery_driver_contact_number}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Delivery Distance: {params.data.delivery_distance}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Future Feature 1</Button>
                <Button size="small">Future Feature 2</Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid xs={4}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Order Notification Message
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Just a reminder. 
                  <br/>
                  There is new Online {orderType} order. Please process it ASAP to avoid lateness. Thank you.
                  <br/>
                  ___________________________
                  <br/>
                  Order Amount: {params.data.order_amount_display}
                  <br/>
                  Customer Name: {params.data.customer_name}
                  <br/>
                  Collection time: {params.data.fulfillment_display}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Future Feature 1</Button>
                <Button size="small">Future Feature 2</Button>
              </CardActions>
            </Card>
          </Grid>          
        </Grid>
      </Box>
    </>
  );
};

export default DetailCellRenderer;
