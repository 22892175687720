import MainLayout from '../../components/MainLayout';
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import { Helmet } from "react-helmet";

export default function OrdersDetail() {
  return (
    <>
    <MainLayout 
      breadcrumbsContent = {
        <BreadcrumbGroup
          items={
            [
              { text: "Order", href: "/orders/" },
              { text: "Detail", href: "/orders/detail" },
            ]
          }
          ariaLabel="Breadcrumbs"
      />
      }
      activeHref={"/orders/detail"}
    >
      <Container
        header={
          <Header
            variant="h2"
            description="Container description"
          >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Order Details</title>
            </Helmet>      
            Order Details
          </Header>
        }
      >
          {/* Orders Home */}
      </Container>          
    </MainLayout>
    </>
  );
}