import MainLayout from '../../components/MainLayout';
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import OrderTable from '../../components/OrderTable';
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import HelpPanel from "@cloudscape-design/components/help-panel";
import {Helmet} from "react-helmet";

export default function OrdersList() {
  return (
    <>
    <MainLayout 
      breadcrumbsContent = {
        <BreadcrumbGroup
          items={
            [
              { text: "Orders", href: "/orders/" },
              { text: "List", href: "/orders/list" },
            ]
          }
          ariaLabel="Breadcrumbs"
      />
      }
      activeHref={"/orders/list"}
      toolsContent={
        <HelpPanel
          // footer={
          //   <div>
          //     <h3>
          //       Learn more <Icon name="external" />
          //     </h3>
          //     <ul>
          //       <li>
          //         <a href="">Link to documentation</a>
          //       </li>
          //       <li>
          //         <a href="">Link to documentation</a>
          //       </li>
          //     </ul>
          //   </div>
          // }
            header={<h2>Orders List Help</h2>}
          >
          <div>
            <p>
              Use the <b>Columns</b> on the right side to:
            </p>
            <ul>
              <li>Re-order the column</li>
              <li>Hide or show the column</li>
            </ul>
    
            <p>
              Use the <b>Filters</b> on the right side to filter the data
            </p>

          </div>
        </HelpPanel>        
      }
    >
      <Container
        header={
          <Header
            variant="h2"
            //description="Orders List"
          >
            Orders List
          </Header>
        }
      >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Orders List</title>
            </Helmet>      
          <OrderTable />
      </Container>          
    </MainLayout>
    </>
  );
}

// export async function getServerSideProps({req}) {
//   // Get idToken from logged in session
//   const token = await getToken({ req });

//   // console.log('token');
//   // console.log(token);

//   const initialDataSeconds = 2630000; // 1 month\
//   const orderTypes = "0,1";

//   console.log("Trigger Orders Import");
//   console.log(process.env.REACT_APP_GETZ_OM_API_URL + "/order?seconds=" + initialDataSeconds + "&types=" + orderTypes);
//   console.log('Authorization: ' + token.idToken);
//   fetch(process.env.REACT_APP_GETZ_OM_API_URL + "/order?seconds=" + initialDataSeconds + "&types=" + orderTypes, {
//     method: 'GET',
//     mode: 'cors',
//     headers: {
//       'Authorization': token.idToken
//     }
//   }).then(res => {
//     console.log('res');
//     console.log(res);
//   });

//   return {
//     props: {}, // will be passed to the page component as props
//   }
// }
