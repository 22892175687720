import { useState } from "react";
import AppLayout from "@cloudscape-design/components/app-layout";
import ContentLayout from "@cloudscape-design/components/content-layout";
import SideNavigation from "@cloudscape-design/components/side-navigation";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Header from "@cloudscape-design/components/header";
import Link from "@cloudscape-design/components/link";

export default function MainLayout({ children, breadcrumbsContent, activeHref, toolsContent }) {
  const [navigationOpen, setNavigationOpen] = useState(false);
  const [toolsDrawerOpen, setToolsDrawerOpen] = useState(false);

  const onNavigationChange = ({detail}) => {
      setNavigationOpen(detail.open);
  };

  const onToolsChange = ({detail}) => {
    setToolsDrawerOpen(detail.open);
  };

  const toggleToolsDrawer = () => {
    setToolsDrawerOpen(!toolsDrawerOpen);
  }

  return (
    <AppLayout
      //navigationHide={true}
      navigationOpen={navigationOpen}
      onNavigationChange={onNavigationChange}
      //navigationWidth={250}
      toolsOpen={toolsDrawerOpen}
      onToolsChange={onToolsChange}
      toolsWidth={300}
      maxContentWidth={Number.MAX_VALUE}
      breadcrumbs={breadcrumbsContent}
      content={
        <ContentLayout
          header={
            <SpaceBetween size="m">
              <Header
                variant="h1"

                info={<Link href="#"><a onClick={toggleToolsDrawer}>Info</a></Link>}
                description="Getz Orders Management"
                // actions={
                //   <Button variant="primary">Button</Button>
                // }
              >
                Getz Orders Management
              </Header>
            </SpaceBetween>
          }
        >
          {children}          
        </ContentLayout>       
      }
      navigation={
        <SideNavigation
          activeHref={activeHref}
          header={{ href: "/orders", text: "Orders" }}
          items={[
            { type: "link", text: "Orders List", href: "/orders/list" },
            // { type: "link", text: "Orders Detail", href: "/orders/detail" },
            // { type: "divider" },
            // {
            //   type: "link",
            //   text: "Notifications",
            //   href: "#/notifications",
            //   info: <Badge color="red">23</Badge>
            // },
            // {
            //   type: "link",
            //   text: "Documentation",
            //   href: "https://example.com",
            //   external: true
            // }
          ]}
        />                 
      }
      tools = {toolsContent}
  >
  </AppLayout>
  );
}