const config = {
    aws_appsync_graphqlEndpoint: "https://h2lftxbg3rgfhmagkugvk7ixfq.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    aws_appsync_region: "ap-southeast-1",
    //aws_appsync_authenticationType: "API_KEY",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_appsync_apiKey: "da2-nil3bjq3dfhsfiwpi2gewcyoty",
    Auth: {
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_APP_CLIENT_ID,
        oauth: {
            domain: process.env.REACT_APP_COGNITO_DOMAIN_URL,
            scope: ['email', 'profile', 'openid'],
            redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN_URL,
            redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT_URL,
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }        
    },    
    API: {
        endpoints: [
            {
                name: "GetzOmApi",
                endpoint: process.env.REACT_APP_API_URL,
            }
        ]
    }
  };

export default config;