import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import "@cloudscape-design/global-styles/index.css"
import { Amplify, Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Routes, Route, Link } from "react-router-dom";
import HomeIndex from "./pages/home/Index";
import OrdersIndex from "./pages/orders/Index";
import OrdersDetail from "./pages/orders/Detail";
import OrdersList from "./pages/orders/List";

import awsconfig from './aws-config';
Amplify.configure(awsconfig);


function App() {
  const [user, setUser] = useState(null);
  const [customState, setCustomState] = useState(null);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          setUser(data);
          break;
        case "signOut":
          setUser(null);
          break;
        case "customOAuthState":
          setCustomState(data);
      }
    });

    Auth.currentAuthenticatedUser()
      .then(currentUser => {
        console.log(user)
        setUser(currentUser)
      })
      .catch(() => {
        // This is the part where we will redirect the user directly to the Cognito Hosted Page UI
        console.log("Not signed in")
        Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google})
    });

    return unsubscribe;
  }, []);
  
  if(user) {
    return (
    <>
    <div className="App">
      <Routes>
        <Route path="/" element={<HomeIndex />} />
        <Route path="/orders/" element={<OrdersIndex />} />
        <Route path="/orders/detail" element={<OrdersDetail />} />
        <Route path="/orders/list" element={<OrdersList />} />
      </Routes>
    </div>
    </>
    )
  }
}

export default App;
