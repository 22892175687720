import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
    useCallback,
  } from "react";
  import * as gen from "./generated";
  import { Auth, API } from 'aws-amplify';
  import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
  import DetailCellRenderer from "../components/DetailCellRenderer";
  import DeliveryDiningOutlinedIcon from '@mui/icons-material/DeliveryDiningOutlined';
  import DiningOutlinedIcon from '@mui/icons-material/DiningOutlined';
  import TakeoutDiningOutlinedIcon from '@mui/icons-material/TakeoutDiningOutlined';
  import Box from '@mui/material/Box';
  import Typography from '@mui/material/Typography';
  import Modal from '@mui/material/Modal';
//  import { useSession } from "next-auth/react";

  import "ag-grid-enterprise";
  import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
  import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const OrderTypeCellRenderer = (props) => {
    switch (props.data.order_type) {
      case 0:
        return (
          <div><DeliveryDiningOutlinedIcon/> {props.value}</div>
        );
      case 1:
        return (
          <div><TakeoutDiningOutlinedIcon/> {props.value}</div>
        );
      case 2:
        return (
          <div><DiningOutlinedIcon/> {props.value}</div>
        );
      default:
          return (
            <div>{props.value}</div>
          );
      }
  }  
  
  const FulfillmentCellRenderer = (props) => {
    // Convert c# tick to javascript tick
    //ticks are in nanotime; convert to microtime
    var ticksToMicrotime = props.data.fulfillment_tick / 10000;
    //ticks are recorded from 1/1/1; get microtime difference from 1/1/1/ to 1/1/1970
    var epochMicrotimeDiff = Math.abs(new Date(0, 0, 1).setFullYear(1));
    //new date is ticks, converted to microtime, minus difference from epoch microtime
    var tickDate = new Date(ticksToMicrotime - epochMicrotimeDiff);

    // get total seconds between the times
    var delta = Math.abs(tickDate.getTime() - Date.now()) / 1000;

    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    var seconds = delta % 60;  // in theory the modulus is not required

    return days + " Days " + hours + " Hours " + minutes + " Minutes";

  } 

  const OrdersTable = () => {
    //const { data: session, status } = useSession();

    const gridRef = useRef(); // Optional - for accessing Grid's API
    const [loadingDialogOpen, setLoadingDialogOpen] = useState(true);
    const [ordersList, setOrdersList] = useState([]); // Set ordersList to Array of Objects, one Object per Row
    const [importantOrdersCount, setImportantOrdersCount] = useState(0);
    const [warningOrdersCount, setWarningOrdersCount] = useState(0);
    const initialDataSeconds = 2630000; // 1 month\
    const orderTypes = "0,1";

    // Each Column Definition results in one Column.
    const [columnDefs, setColumnDefs] = useState([
      {
        field: "order_status_display",
        headerName: "Order Status",
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          if (nodeA && nodeB) {
            // This if is to fix issue when we click the burger menu, somehow it calls comparator with null nodeA
            const nodeAValue = nodeA.data.order_status;
            const nodeBValue = nodeB.data.order_status;
            return nodeAValue > nodeBValue ? 1 : -1;
          }
        },
        cellRenderer: "agGroupCellRenderer",
        sort: "asc",
        cellClass: (params) => {
          switch(params.data.order_status_display){
            case 'New Order':
              return 'new-order';
            case 'Confirmed':
              return 'confirmed';
            case 'Completed':
              return 'completed';
          };
        }
      },
      { 
          field: "order_type_display", 
          filter: "agTextColumnFilter",
          headerName: "Order Type", 
          cellRenderer: OrderTypeCellRenderer,
      },
      { field: "outlet_name", headerName: "Merchant Outlet" },
      {
        field: "time_to_fulfillment_display",
        filter: "agTextColumnFilter",
        headerName: "Time to Fulfillment",
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          if (nodeA && nodeB) {
            // This if is to fix issue when we click the burger menu, somehow it calls comparator with null nodeA
            const nodeAValue = nodeA.data.time_to_fulfillment_tick;
            const nodeBValue = nodeB.data.time_to_fulfillment_tick;
            return nodeAValue > nodeBValue ? 1 : -1;
          }
        },
        cellRenderer: FulfillmentCellRenderer,
      },
      {
        field: "order_amount_display",
        filter: "agTextColumnFilter",
        headerName: "Order Amount",
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          if (nodeA && nodeB) {
            // This if is to fix issue when we click the burger menu, somehow it calls comparator with null nodeA
            const nodeAValue = nodeA.data.order_amount;
            const nodeBValue = nodeB.data.order_amount;
            return nodeAValue > nodeBValue ? 1 : -1;
          }
        },
      },
      {
        field: "fulfillment_display",
        filter: "agTextColumnFilter",
        headerName: "Fulfillment",
      },
      {
        field: "elapsed_time_display",
        headerName: "Elapsed Time",
        filter: "agTextColumnFilter",
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          if (nodeA && nodeB) {
            // This if is to fix issue when we click the burger menu, somehow it calls comparator with null nodeA
            const nodeAValue = nodeA.data.elapsed_time_tick;
            const nodeBValue = nodeB.data.elapsed_time_tick;
            return nodeAValue > nodeBValue ? 1 : -1;
          }
        },
      },
      {
        field: "created_date_display",
        filter: "agTextColumnFilter",
        headerName: "Created",
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          if (nodeA && nodeB) {
            // This if is to fix issue when we click the burger menu, somehow it calls comparator with null nodeA
            const nodeAValue = nodeA.data.created_date;
            const nodeBValue = nodeB.data.created_date;
            return nodeAValue > nodeBValue ? 1 : -1;
          }
        },
      },
      {
        field: "order_code",
        filter: "agTextColumnFilter",
        headerName: "Order Code",
      },
      {
        field: "transaction_id",
        filter: "agTextColumnFilter",
        headerName: "Getz Id",
      },
      {
        field: "reference_code",
        filter: "agTextColumnFilter",
        headerName: "Getzpay Id",
      },
      {
        field: "payment_status_display",
        headerName: "Payment Status",
      },
    ]);
  
    const getRowId = (params) => params.data.id;
  
    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo(() => ({
      filter: true,
      floatingFilter: true,
      resizable: true,
      sortable: true,
      suppressMenu: true,
      //wrapText: true,
      //autoHeight: true,
    }));
  
    // // Example of consuming Grid Event
    // const cellClickedListener = useCallback((event) => {
    //   console.log("cellClicked", event);
    // }, []);

    const [idToken, setIdToken] = useState(null)

    Auth.currentSession().then(res=>{
        const idToken =  res.getIdToken().getJwtToken();
        setIdToken(idToken);
    })

    // Example load data from sever
    useEffect(() => {
        if(idToken) {
            // console.log("triggerOrdersDataImport");
            // console.log(idToken);
            API.get("GetzOmApi","/order",{
                headers: {
                    Authorization: "Bearer " + idToken,
                }, // OPTIONAL
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {  // OPTIONAL
                    
                    seconds: initialDataSeconds,
                    types: orderTypes,
                },
            })
            .then(response => {
                console.log('triggerOrdersDataImport success')
            })
            .catch(error=>{
                console.log('triggerOrdersDataImport error')
            });
        }
      //setOrdersList(mockData);
    }, [idToken]);
  
    //Define the channel name here
    let channel = "getzom";
  
    useEffect(() => {
      //Subscribe via WebSockets
      const subscription = gen.subscribe(channel, ({ data }) => {
        setLoadingDialogOpen(false);
        let orderDataJson = JSON.parse(data);
        let newOrdersList = ordersList;

        // We need to handle both array and non array scenarios
        if(Array.isArray(orderDataJson)) {
          orderDataJson.forEach(orderData => {
            // console.log(orderData.fulfillment_tick);
            // Only accept Delivery and Pick-up
            if (orderData.order_type !== 0 && orderData.order_type !== 1) {
              return;
            }
      
            let existing = false;
            newOrdersList = newOrdersList.map((existingOrder) => {
              if (existingOrder.id === orderData.id) {
                existing = true;
                return { ...existingOrder, ...orderData };
              }
      
              return existingOrder;
            });
      
            if (!existing || newOrdersList === null) {
              newOrdersList.push(orderData);
            }
          });
        }
        else {
            // Only accept Delivery and Pick-up
            if (orderDataJson.order_type !== 0 && orderDataJson.order_type !== 1) {
              return;
            }
      
            let existing = false;
            newOrdersList = newOrdersList.map((existingOrder) => {
              if (existingOrder.id === orderDataJson.id) {
                existing = true;
                return { ...existingOrder, ...orderDataJson };
              }
      
              return existingOrder;
            });
      
            if (!existing || newOrdersList === null) {
              newOrdersList.push(orderDataJson);
            }
        } 

        let importantOrdersCountCurrent = 0;
        let warningOrdersCountCurrent = 0;
        newOrdersList.forEach(newOrder => {
          if(newOrder.delivery_operator_name !== "Internal" 
          && newOrder.order_type === 0 
          && newOrder.time_to_fulfillment_tick <= 0 
          && (newOrder.delivery_status === "ASSIGNING_DRIVER" 
              || newOrder.delivery_status === "ON_GOING"
              || newOrder.delivery_status === "QUEUEING"
              || newOrder.delivery_status === "PENDING"
          )) {
            importantOrdersCountCurrent++;
          };

          if(newOrder.delivery_operator_name !== "Internal" 
          && newOrder.order_type === 0 
          && newOrder.time_to_fulfillment_tick <= 0
          && newOrder.delivery_status === "NA") {
            warningOrdersCountCurrent++;
          }

        });

        console.log("Orders Count: " + newOrdersList.length);
        setOrdersList(newOrdersList);
        setImportantOrdersCount(importantOrdersCountCurrent);
        setWarningOrdersCount(warningOrdersCountCurrent);
      });
      return () => subscription.unsubscribe();
    }, [channel, ordersList]);
  
    const statusBar = useMemo(() => {
      return {
        statusPanels: [
          { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
          { statusPanel: "agTotalRowCountComponent", align: "center" },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      };
    }, []);
  
    const rowClassRules = useMemo(() => {
      return {
        // row style function
        "delivery-warning": (params) => {
          return params.data.delivery_operator_name !== "Internal" 
          && params.data.order_type === 0 
          && params.data.time_to_fulfillment_tick <= 0
          && params.data.delivery_status === "NA";
        },
        "delivery-important": (params) => {
          return params.data.delivery_operator_name !== "Internal" 
          && params.data.order_type === 0 
          && params.data.time_to_fulfillment_tick <= 0 
          && (params.data.delivery_status === "ASSIGNING_DRIVER" 
             || params.data.delivery_status === "ON_GOING"
             || params.data.delivery_status === "QUEUEING"
             || params.data.delivery_status === "PENDING"
          );
        },
        //// row style expression
        //"delivery-important": 'data.order_status_display === "Confirmed"'
      };
    }, []);
  
    const sideBar = useMemo(() => {
      return {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivotMode: true,
              suppressRowGroups: true,
              suppressValues: true,
            },
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      };
    }, []);
  
    // This is to resize the columns size to fit the width of the window
    // and also to resize the columns size when we resize the window
    const onGridReady = useCallback((params) => {
      gridRef.current.api.sizeColumnsToFit();
      window.onresize = () => {
        gridRef.current.api.sizeColumnsToFit();
      };
    }, []);
  
    return (
      <>
      {/*<button onClick={triggerOrdersDataImport}>Get New Data</button>*/}
      <Modal
        open={loadingDialogOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Loading 1 Month Data, please do not refresh the page!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            It may take a few seconds to complete. After that, the dashboard will reflect new updates in real time.
            <br/>
            Check the columns and filter features on the sidebar on the right.
          </Typography>
        </Box>
      </Modal>      

      {/* <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Getz Order Monitor
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={warningOrdersCount} color="error" max={99999}>
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={importantOrdersCount} color="error" max={99999}>
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </Box> */}

      <div>
        {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
        <div className="ag-theme-alpine" style={{ width: "100%", height: 768 }}>
          <AgGridReact
            ref={gridRef} // Ref for accessing Grid's API
            onGridReady={onGridReady}
            rowData={ordersList} // Row Data for Rows
            getRowId={getRowId}
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            rowSelection="multiple" // Options - allows click selection of rows
            //onCellClicked={cellClickedListener} // Optional - registering for Grid Event
            masterDetail={true}
            detailCellRenderer={DetailCellRenderer}
            sideBar={sideBar}
            statusBar={statusBar}
            rowClassRules={rowClassRules}
            detailRowAutoHeight={true}
            ensureDomOrder={true}
            enableCellTextSelection={true}            
            //detailRowHeight={350}
          />
        </div>
      </div>
      </>
    );
  };
  
  export default OrdersTable;
  